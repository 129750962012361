/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import "./PositionList.scss";
import axios from "axios";

const PostGrid = () => {
  const [axiosData, setAxiosData] = useState(null);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(
        "https://sheets.googleapis.com/v4/spreadsheets/1ViAWBU6rAfU3EIEpjaCAP6aDJEIxmZVV4YJ3vxJEFYo/values/2:30?key=AIzaSyDw5asg8ciaDk7CWwxQHBuKD3DzKSHRZfE"
      );
      setAxiosData(response.data);
      // setLoading(false);
    };

    fetchData();
  }, []);

  // const disableCategory = (catID, cat) => {
  //   if (lengthOfCategory(cat) === 0) {
  //     const categoryHeader = document.querySelector(`#header-${catID}`);
  //     categoryHeader.style.display = "none";
  //   }
  // };

  // const lengthOfCategory = (cat, catID) => {
  //   let values = axiosData?.values;

  //   const length = values
  //     ?.map((v) => v[4].startsWith(cat))
  //     .filter(Boolean).length;

  //   return length;
  // };

  // disableCategory(1, "EUROPE / FAR EAST");
  // disableCategory(2, "US GULF / EUROPE");
  // disableCategory(3, "INTRA EUROPE");
  // disableCategory(4, "EUROPE / US GULF");
  // disableCategory(5, "FAR EAST / PG / MED SEA");
  // disableCategory(6, "FAR EAST / MED SEA / BLACK SEA / EUROPE");
  // disableCategory(7, "FAR EAST / EUROPE");
  // disableCategory(8, "FAR EAST / US EAST COAST / GREAT LAKES");
  // disableCategory(9, "INTRA ASIA");
  // disableCategory(10, "PERSIAN GULF / SOUTHEAST ASIA / FAR EAST");
  // disableCategory(11, "EUROPE / WEST AFRICA / BRAZIL");
  // disableCategory(12, "SPECIAL POSITION");

  const allCategories = axiosData?.values.map((c) => c[4]);
  const uniqueCategories = [...new Set(allCategories)];

  // if (uniqueCategories.length > 0) {
  //   uniqueCategories.forEach((category, index) => {});
  // }

  const createRegion = (regionName) => {
    return (
      <div className="position-region">
        {regionName.startsWith("SPECIAL") ? (
          <h5 className="region-header-special">
            {regionName.split("-")[1].toUpperCase()}
          </h5>
        ) : (
          <h5 className="region-header">{regionName.toUpperCase()}</h5>
        )}
        <table>
          <tbody>
            {axiosData?.values.map((item, index) => {
              if (item[4].startsWith(regionName)) {
                return (
                  <tr key={index}>
                    <td>
                      <span className="name-of-ship">
                        {item[0].split("=")[0].toUpperCase()}
                      </span>
                      <br />
                      <span className="specs-of-ship">
                        {item[0].split("=")[1].split("-")[0]}
                        {console.log(item[0].split("=")[1].split("-")[0])}
                        <br />
                        {item[0].split("=")[1].split("-")[1] &&
                          item[0].split("=")[1].split("-")[1]}
                        <br />
                        {item[0].split("=")[1].split("-")[2] &&
                          item[0].split("=")[1].split("-")[2]}
                      </span>
                    </td>
                    <td>
                      <span className="inner-table-header">
                        Current Location
                      </span>
                      <br />
                      {/* {item[1].split("-")[0]} */}
                      {item[1].split("-")[0].toLowerCase().includes("med")
                        ? item[1].split("-")[0].replace("Med", "Mediterranean")
                        : item[1].split("-")[0]}

                      <br />
                      {item[1].split("-")[1]}
                    </td>
                    <td>
                      {" "}
                      <span className="inner-table-header">Direction</span>
                      <br />
                      {item[2]}
                    </td>
                    <td>
                      <span className="inner-table-header">Looking for</span>
                      <br />
                      {item[3]}
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="position-list">
      {uniqueCategories?.map((reg, index) => createRegion(reg))}
    </div>
    // <div className="position-list">

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-12">
    //       {uniqueCategories[0]?.startsWith("SPECIAL POSITION")
    //         ? uniqueCategories[0]
    //         : "SPECIAL POSITION"}
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4].startsWith("SPECIAL POSITION")) {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-1">
    //       EUROPE / FAR EAST
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "EUROPE / FAR EAST") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 id="header-2" className="usgulf-europe region-header">
    //       US GULF / EUROPE
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "US GULF / EUROPE") {
    //             const usGulfEuropeh5 = document.querySelector(".usgulf-europe");
    //             usGulfEuropeh5.style.display = "block";
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>
    //   <div className="position-region">
    //     <h5 className="region-header" id="header-3">
    //       INTRA EUROPE
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "INTRA EUROPE") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-4">
    //       EUROPE / US GULF
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "EUROPE / US GULF") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-5">
    //       FAR EAST / PERSIAN GULF / MEDITERRANEAN SEA
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "FAR EAST / PG / MED SEA") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-6">
    //       FAR EAST / MEDITERRANEAN SEA / BLACK SEA / EUROPE
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "FAR EAST / MED SEA / BLACK SEA / EUROPE") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-7">
    //       FAR EAST / EUROPE
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "FAR EAST / EUROPE") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-8">
    //       FAR EAST / US EAST COAST / GREAT LAKES
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "FAR EAST / US EAST COAST / GREAT LAKES") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-9">
    //       FINTRA ASIA
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "INTRA ASIA") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-10">
    //       PERSIAN GULF / SOUTHEAST ASIA / FAR EAST
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "PERSIAN GULF / SOUTHEAST ASIA / FAR EAST") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>

    //   <div className="position-region">
    //     <h5 className="region-header" id="header-11">
    //       EUROPE / WEST AFRICA / BRAZIL
    //     </h5>
    //     <table>
    //       <tbody>
    //         {axiosData?.values.map((item, index) => {
    //           if (item[4] === "EUROPE / WEST AFRICA / BRAZIL") {
    //             return (
    //               <tr key={index}>
    //                 <td>
    //                   <span className="name-of-ship">
    //                     {item[0].split("=")[0].toUpperCase()}
    //                   </span>
    //                   <br />
    //                   <span className="specs-of-ship">
    //                     {item[0].split("=")[1]}
    //                   </span>
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">
    //                     Current Location
    //                   </span>
    //                   <br />
    //                   {item[1].split("-")[0]}
    //                   <br />
    //                   {item[1].split("-")[1]}
    //                 </td>
    //                 <td>
    //                   {" "}
    //                   <span className="inner-table-header">Direction</span>
    //                   <br />
    //                   {item[2]}
    //                 </td>
    //                 <td>
    //                   <span className="inner-table-header">Looking for</span>
    //                   <br />
    //                   {item[3]}
    //                 </td>
    //               </tr>
    //             );
    //           }
    //         })}
    //       </tbody>
    //     </table>
    //   </div>
    // </div>
  );
};

export default PostGrid;
