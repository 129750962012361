import PostGrid from "./pages/PositionList";
import "./App.css";



export default function App() {
  return (
    <div className="App">
      <PostGrid />
    </div>
  );
}
